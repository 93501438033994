/* globals $, WwGgbApplet, _E, Cookies */
import { throttle, delay } from 'underscore'
import Backbone from 'backbone'
import EdfinityCookie from 'models/cookie'
import {
  isLargeDisplay,
  optimalViewing,
  inIFrame,
  showModal,
} from 'helpers/view'
import { flashMessage } from 'helpers/flash'
import { getAirbrakeClient } from 'utils/airbrake'
import LeftSheet from 'views/sheets/left'
import LeftDrawer from 'views/sheets/left_drawer'
import ProgressBar from 'views/progress_bar'
import Header from 'views/header/header'
import SearchBar from 'views/header/search_bar'
import MathquillPalette from 'helpers/mathquill_palette'
import TrialSubscription from 'views/subscriptions/trial'
import BetaOptIn from 'views/beta/opt_in'
import { I18n } from 'i18n-js'
import translations from 'i18n/translations.json'
import { listenForOfflineEvents } from 'helpers/offline'

export default {
  initialize () {
    this.listenToGlobalEvents()
  },

  handleDocumentReady () {
    if (!optimalViewing() && window.location.pathname !== '/product/browsers') {
      $('#browser-alert').removeClass('d-none').addClass('d-inline-block')
    }

    // site-wise tooltips
    $("[data-toggle='tooltip']").tooltip?.()

    this.globalTranslations()
    this.showOneTimeModal()
    this.renderGlobalViews()
    this.xdomain()
    this.configureGeogebra()
    this.renderCookieFlash()
    this.renderFlash()
    this.renderConsentBanner()
    this.listenForTouch()
    this.listenForResize()
    listenForOfflineEvents()
    this.configureAirbrake()
    this.configureSkipToMain()
    this.styleIFrame()
    this.makeAccessible()

    // start Backbone router
    Backbone.history.start({ pushState: true, hashChange: false })
  },

  globalTranslations () {
    // todo: replace this global
    const i18n = new I18n(translations)
    i18n.defaultLocale = _E.i18n.defaultLocale
    i18n.locale = _E.i18n.locale
    i18n.timeZone = _E.i18n.timeZone
    i18n.currencySymbol = _E.i18n.currencySymbol
    window.I18n = i18n
  },

  xdomain () {
    const bootstrap = { user_id: _E.currentUser?.id }
    document.addEventListener(
      'message',
      (event) => window.postMessage(bootstrap, '*'),
      false
    )
  },

  renderCookieFlash () {
    let msg, type
    const cookie = new EdfinityCookie({ namespace: 'flash' })
    if ((type = cookie.get('type')) && (msg = cookie.get('message'))) {
      flashMessage(msg, type)
      cookie.remove()
    }
  },

  renderFlash () {
    _E.flash.forEach((f) =>
      setTimeout(() => {
        flashMessage(f.message, f.type)
      }, 300)
    )
  },

  configureGeogebra () {
    // used to ensure that we hide various additional UI elements when hiding
    // geogebra-linked inputs
    WwGgbApplet.configure({
      onHideAnswer (containerEl, answerId) {
        containerEl.find(`#mq-${answerId}`).hide() // MathQuill
        containerEl.find(`#mq-${answerId} + .mq-dropdown`).hide() // MathQuill
        containerEl.find(`#${answerId}`).addClass('hide-marks geogebra-hidden')
        containerEl.find(`#${answerId} + .answer-mark`).hide()
        containerEl.find(`#${answerId} + .math-field`).hide()
      },
      onHideAnswers (containerEl) {
        containerEl.find('[id^="mq-AnSwEr"]').hide() // MathQuill
        containerEl
          .find('[id^="AnSwEr"]')
          .addClass('hide-marks geogebra-hidden')
        containerEl.find('[id^="AnSwEr"] + .answer-mark').hide()
        containerEl.find('[id^="AnSwEr"] + .math-field').hide()
      },
    })
  },

  listenForTouch () {
    $(window).one('touchstart', () => {
      $('body').addClass('is-touch')
      this.touch = true
    })
  },

  listenForResize () {
    $(window).resize(throttle(this.onResize.bind(this), 100))
    this.onResize()
  },

  renderGlobalViews () {
    if ($('#primary-header').get(0)) {
      _E.primaryHeader = new Header({ el: '#primary-header' }).render()
    }
    _E.progressBar = new ProgressBar({ el: '#header-progress-bar' }).render()
    if ($('#search-bar').get(0)) {
      _E.searchBar = new SearchBar({ el: $('#search-bar') }).render()
    }
    _E.leftDrawer = new LeftDrawer({ el: '#left-drawer' }).render()
    if ($('#left-sheet').get(0)) {
      _E.leftSheet = new LeftSheet({ el: '#left-sheet' }).render()
    }
    _E.mathquill = new MathquillPalette()
  },

  listenToGlobalEvents () {
    _E.globalEvents = new Backbone.Model()
    _E.globalEvents.on('flash', (e) => flashMessage(e.message, e.type))
  },

  renderConsentBanner () {
    const consented = Cookies.get('cookie_eu_consented')
    if (!consented || consented == 'false') {
      $('#consent-banner').removeClass('d-none')
      $('#consent-banner').attr('aria-hidden', false)
    }
  },

  hideScrollbars () {
    $('.hide-scrollbars').each(function () {
      this.style.paddingBottom = this.offsetHeight - this.clientHeight + 'px'
    })
  },

  configureAirbrake () {
    const airbrake = getAirbrakeClient()

    if (airbrake?.addFilter && _E.currentUser) {
      airbrake.addFilter((notice) => {
        notice.context.user_id = _E.currentUser.id
        notice.context.user_login = _E.currentUser.login
        return notice
      })
    }
  },

  configureSkipToMain () {
    $('#skip-to-main').focus(() => $('#content').attr({ tabindex: -1 }))
  },

  styleIFrame () {
    $('html').toggleClass('iframe', inIFrame())
  },

  showOneTimeModal () {
    // will only be present if needed
    if ($('#tos-modal').get(0)) {
      showModal($('#tos-modal'), { lockInteraction: true })
    } else if ($('#user-message-modal').get(0)) {
      showModal($('#user-message-modal'), { lockInteraction: true })
    } else if ($('#trial-modal').get(0)) {
      new TrialSubscription({ subscriptionId: _E.subscription_id }).show()
    } else if ($('#opt-in-modal').get(0)) {
      new BetaOptIn().show()
    }
  },

  // hacks to bypass SiteImprove accessibility checks
  makeAccessible () {
    $('.ui-helper-hidden-accessible').attr('aria-live', 'polite')
    $('#HW_frame').attr('title', 'Recent notifications')
    delay(() => $('#launcher').attr('tabindex', null), 2000)
  },

  onResize () {
    const isLarge = isLargeDisplay()

    $('#sidebar').attr('aria-hidden', !isLarge)
    $('#sidebar').toggleClass('invisible', !isLarge)
  },
}
