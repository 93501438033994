import _templates_mathquill_palette_ from 'templates/mathquill_palette'
/* globals $, JST */
import { intersection } from 'underscore'
import BaseView from 'views/base'
import { typeset } from 'helpers/mathjax'
import { uniqueId } from 'utils/common'

export default class MathquillPalette extends BaseView {
  events () {
    return {
      'click .epsilon': 'onClickEpsilon',
      'click .lambda': 'onClickLambda',
      'click .pi': 'onClickPi',
      'click .e': 'onClickEuler',
      'click .infinity': 'onClickInfinity',
      'click .ninfinity': 'onClickNegativeInfinity',

      'click .gte': 'onClickGte',
      'click .lte': 'onClickLte',
      'click .sqrt': 'onClickSqrt',
      'click .nth-root': 'onClickNthRoot',

      'click .frac': 'onClickFrac',
      'click .abs': 'onClickAbs',
      'click .exp': 'onClickExp',
      'click .expe': 'onClickExpE',

      'click .ln': 'onClickNaturalLog',
      'click .logten': 'onClickLogten',
      'click .logb': 'onClickLogB',

      'click .factorial': 'onClickFactorial',
      'click .prime': 'onClickPrime',
      'click .union': 'onClickUnion',
      'click .emptyset': 'onClickEmptySet',

      'click .cos': 'onClickCos',
      'click .arccos': 'onClickArccos',
      'click .cosh': 'onClickCosh',

      'click .sin': 'onClickSin',
      'click .arcsin': 'onClickArcsin',
      'click .sinh': 'onClickSinh',

      'click .tan': 'onClickTan',
      'click .arctan': 'onClickArctan',
      'click .tanh': 'onClickTanh',

      'click .sec': 'onClickSec',
      'click .arcsec': 'onClickArcsec',
      'click .sech': 'onClickSech',

      'click .cot': 'onClickCot',
      'click .arccot': 'onClickArccot',
      'click .coth': 'onClickCoth',

      'click .csc': 'onClickCsc',
      'click .arccsc': 'onClickArccsc',
      'click .csch': 'onClickCsch',

      'click .alpha': 'onClickAlpha',
      'click .beta': 'onClickBeta',
      'click .gamma': 'onClickGamma',
      'click .phi': 'onClickPhi',
      'click .rho': 'onClickRho',
      'click .tau': 'onClickTau',
      'click .theta': 'onClickTheta',
    }
  }

  render (cid, features = []) {
    this.$el.html(
      _templates_mathquill_palette_({
        hasFeature: (feature) => {
          return features.includes(feature)
        },
        hasGreekFeatures:
          intersection(features, [
            'alpha',
            'beta',
            'epsilon',
            'gamma',
            'lambda',
            'phi',
            'rho',
            'tau',
            'theta',
          ]).length > 0,
        cid,
      })
    )
    this.$el.on('click', (e) => e.stopPropagation())
    this.initTabbing(cid)
    typeset(this.$el.get(0))
    this.refreshTooltips()

    return this
  }

  initTabbing (cid) {
    $(`#mq-tabs-${cid} a`).on('click', function (e) {
      $(`#mq-tabs-${cid} a`).removeClass('active')
      $(this).tab('show')
      e.preventDefault()
    })
  }

  attachPalette (mathField, cid, flex = false, features = []) {
    // wrap Mathquill element with a div containing a button to open the editor
    const id = uniqueId('mq-button')
    const mathFieldEl = $(mathField.el())

    if (flex) {
      mathFieldEl.addClass('flex-grow-1')
    }

    mathFieldEl.wrap(
      `<div class="overflow-auto ${flex ? 'd-flex' : 'd-inline'} ${
        flex ? 'w-100' : 'py-1'
      }"><div class='d-${flex ? 'flex' : 'inline-block'} position-relative${
        flex ? ' d-flex flex-grow-1' : ''
      }'></div></div>`
    )
    mathFieldEl.parent().append(`\
<div class='dropdown mq-dropdown'> \
<button id='${id}' class='btn p-0' type='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false' aria-label='Show Mathquill buttons'> \
<span class='fa-stack' aria-hidden='true'> \
<span class='fas fa-pencil-alt fa-stack-1x'></span> \
<span class='fas fa-caret-down fa-stack-1x'></span> \
</span> \
</button> \
<div class='dropdown-menu dropdown-menu-right' aria-labelledby='${id}'></div> \
</div>`)

    // active classes control opacity of the button
    const dropdown = mathFieldEl.parent().find(`#${id}`).parent()

    dropdown.find('button').click(() => dropdown.addClass('dropdown-active'))
    dropdown.on('show.bs.dropdown', (e) => {
      mathField.focus()
      this.setElement($(e.currentTarget).find('.dropdown-menu'))
      mathFieldEl.closest('.attempt-question').css({ 'overflow-x': 'visible' })
      mathFieldEl
        .closest('.overflow-auto')[0]
        .style.setProperty('overflow', 'visible', 'important')

      const container = mathFieldEl.closest('.attempt-question-container')[0]

      container.style.setProperty('overflow', 'visible', 'important')
      container.style.setProperty('max-width', 'inherit', 'important')

      mathFieldEl.closest('.editable-wrapper').css({ overflow: 'visible' })
      this.render(cid, features)
    })
    dropdown.on('hidden.bs.dropdown', (e) => {
      mathFieldEl
        .closest('.attempt-question')[0]
        .style.removeProperty('overflow-x')
      mathFieldEl.closest('.overflow-auto')[0].style.removeProperty('overflow')

      const container = mathFieldEl.closest('.attempt-question-container')[0]

      container.style.removeProperty('overflow')
      container.style.removeProperty('max-width')

      mathFieldEl.closest('.editable-wrapper').css({ overflow: 'auto' })
      dropdown.removeClass('dropdown-active')
    })

    // editor opens for currently focused math field
    const textarea = $(mathField.__controller.textarea[0])

    textarea.on('focus', () => {
      this.mathField = mathField
      dropdown.addClass('textarea-active')
    })
    textarea.on('blur', () => {
      dropdown.removeClass('textarea-active')
    })

    return mathFieldEl.parent()
  }

  // the command is sent to mathquill which translates it in what we need.
  addCmdToField (cmd) {
    this.mathField.cmd(cmd)
    this.mathField.focus()
  }

  // Some commands need to use write because we need the latex to be interpreted
  writeLtxToField (cmd) {
    this.mathField.write(cmd)
    this.mathField.focus()
  }

  //
  // event handlers
  //
  onClickInfinity () {
    this.writeLtxToField('infinity')
  }

  onClickNegativeInfinity () {
    this.addCmdToField('-')
    this.writeLtxToField('infinity')
  }

  onClickEpsilon () {
    this.addCmdToField('\\epsilon')
  }

  onClickLambda () {
    this.addCmdToField('\\lambda')
  }

  onClickPi () {
    this.addCmdToField('\\pi')
  }

  onClickEuler () {
    this.writeLtxToField('e')
  }

  onClickGte () {
    this.addCmdToField('>')
    this.addCmdToField('=')
  }

  onClickLte () {
    this.addCmdToField('<')
    this.addCmdToField('=')
  }

  onClickSqrt () {
    this.addCmdToField('\\sqrt')
  }

  onClickNthRoot () {
    this.addCmdToField('\\nthroot')
  }

  onClickFrac () {
    this.addCmdToField('/')
  }

  onClickAbs () {
    this.addCmdToField('|')
  }

  onClickExp () {
    this.addCmdToField('^')
  }

  onClickExpE () {
    this.addCmdToField('e')
    this.addCmdToField('^')
  }

  onClickFactorial () {
    this.writeLtxToField('fact')
    this.addCmdToField('(')
  }

  onClickPrime () {
    this.addCmdToField("'")
  }

  onClickUnion () {
    this.writeLtxToField('U')
  }

  onClickEmptySet () {
    this.mathField.typedText('{}')
    this.mathField.focus()
  }

  onClickNaturalLog () {
    this.addCmdToField('\\ln')
    this.addCmdToField('(')
  }

  onClickLogten () {
    this.writeLtxToField('\\log_{10}')
    this.addCmdToField('(')
  }

  onClickLogB () {
    this.writeLtxToField('\\log_{}')
    this.addCmdToField('(')
    this.mathField.keystroke('Left Down')
  }

  onClickSin () {
    this.addCmdToField('\\sin')
    this.addCmdToField('(')
  }

  onClickArcsin () {
    this.addCmdToField('\\arcsin')
    this.addCmdToField('(')
  }

  onClickSinh () {
    this.addCmdToField('\\sinh')
    this.addCmdToField('(')
  }

  onClickCos () {
    this.addCmdToField('\\cos')
    this.addCmdToField('(')
  }

  onClickArccos () {
    this.addCmdToField('\\arccos')
    this.addCmdToField('(')
  }

  onClickCosh () {
    this.addCmdToField('\\cosh')
    this.addCmdToField('(')
  }

  onClickTan () {
    this.addCmdToField('\\tan')
    this.addCmdToField('(')
  }

  onClickArctan () {
    this.addCmdToField('\\arctan')
    this.addCmdToField('(')
  }

  onClickTanh () {
    this.addCmdToField('\\tanh')
    this.addCmdToField('(')
  }

  onClickSec () {
    this.addCmdToField('\\sec')
    this.addCmdToField('(')
  }

  onClickArcsec () {
    this.addCmdToField('\\arcsec')
    this.addCmdToField('(')
  }

  onClickSech () {
    this.addCmdToField('\\sech')
    this.addCmdToField('(')
  }

  onClickCot () {
    this.addCmdToField('\\cot')
    this.addCmdToField('(')
  }

  onClickArccot () {
    this.addCmdToField('\\arccot')
    this.addCmdToField('(')
  }

  onClickCoth () {
    this.addCmdToField('\\coth')
    this.addCmdToField('(')
  }

  onClickCsc () {
    this.addCmdToField('\\csc')
    this.addCmdToField('(')
  }

  onClickArccsc () {
    this.addCmdToField('\\arccsc')
    this.addCmdToField('(')
  }

  onClickCsch () {
    this.addCmdToField('\\csch')
    this.addCmdToField('(')
  }

  onClickAlpha () {
    this.addCmdToField('\\alpha')
  }

  onClickBeta () {
    this.addCmdToField('\\beta')
  }

  onClickGamma () {
    this.addCmdToField('\\gamma')
  }

  onClickPhi () {
    this.addCmdToField('\\phi')
  }

  onClickRho () {
    this.addCmdToField('\\rho')
  }

  onClickTau () {
    this.addCmdToField('\\tau')
  }

  onClickTheta () {
    this.addCmdToField('\\theta')
  }
}
