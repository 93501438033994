/* global _E */
import { any } from 'underscore'
import Backbone from 'backbone'

class Router extends Backbone.Router {
  static setRoutes () {
    this.prototype.routes = {
      '': 'homeIndex',
      'about/collegiate': 'aboutCollegiate',
      'about/testimonials': 'aboutTestimonials',
      'about/webwork': 'aboutWebwork',
      access_codes: 'accessCodesIndex',
      activations: 'activationsIndex',
      'assessments/new': 'assessmentNew',
      'assessments/ldb_launch': 'assessmentLdbLaunch',
      'assessments/:id/assessment_sessions': 'assessmentSessionNew',
      'assessments/:id(/:route)': 'assessmentSessionShow',
      'assessments/:id/assessment_sessions/new': 'assessmentSessionNew',
      'assessment_items/:id(/:route)': 'assessmentItemShow',
      'assessment_sessions/:id(/:route)': 'assessmentSessionShow',
      'assignments/:id/metrics(/:route)': 'assignmentMetrics',
      'assignments/:aid/print_variations/:id': 'printVariationShow',
      'assignments/:id(/:route)': 'assignmentShow',
      'cart/edit': 'cartEdit',
      'cart/checkout': 'cartCheckout',
      catalog: 'productsIndex',
      contest_sponsors: 'contestSponsorIndex',
      environment_templates: 'environmentTemplatesIndex',
      'environment_templates/:id(/:route)': 'environmentTemplateShow',
      'join/:code': 'sectionJoin',
      'lti/select_assignment': 'ltiSelectAssignment',
      'ltia/course_link': 'ltiaCourseLink',
      'ltia/deep_link/:id': 'ltiaDeepLink',
      'ltia/registration/edit': 'ltiaRegistrationEdit',
      'ltia/registration/new': 'ltiaRegistrationEdit',
      'orders/all': 'ordersAll',
      'orders/:id': 'ordersShow',
      'orders/:id/edit': 'ordersEdit',
      'problems/:id(/:route)': 'problemShow',
      'problem_sets/:sid/problems/:id(/:route)': 'problemShowInProblemSet',
      'problem_sets/:id/assessments/new': 'assessmentNew',
      product_categories: 'productCategoryIndex',
      'product_categories/:id/edit': 'productCategoryEdit',
      products: 'productsIndex',
      'products/:id': 'productShow',
      'products/:id/edit(/:route)': 'productEdit',
      'registrations/:id(/:route)': 'registrationShow',
      registrations: 'registrationsIndex',
      sections: 'sectionsIndex',
      'sections/:id/renew': 'sectionRenew',
      'sections/:id(/:route)': 'sectionShow',
      'schools/:id(/:route)(/:uid)': 'schoolShow',
      sketches: 'sketchesIndex',
      'sketches/:id(/:route)': 'sketchShow',
      'sketch_requests/:id(/:route)': 'sketchRequestShow',
      sketch_requests: 'sketchRequestsIndex',
      'study(/:route)': 'sbIndex',
      'taxonomy_nodes(/:id)(/:route)': 'taxonomyNodeShow',
      'support(/:route)(/:id)': 'supportIndex',
      'users/edit_student': 'userEditStudent',
      'users/edit_educator': 'userEditEducator',
      'users/sign_in': 'sessionNew',
      'users/sign_up': 'usersIndex',
      'users/update_educator': 'userEditEducator',
      'users/update_student': 'userEditStudent',
      'users/login_2fa': 'userLogin2FA',
      'users(/:route)': 'usersIndex',
      'users/': 'usersIndex',
      '*notfound': 'notfound',
    }
  }

  initialize () {
    this.viewInstance = this.viewName = this.modal = null
  }

  getView (callback, id) {
    const route = this.getCurrentRoute()
    if (this.route === route) {
      return typeof this.viewInstance.route === 'function'
        ? this.viewInstance.route(Backbone.history.fragment, id)
        : undefined
    } else {
      this.route = route
      Promise.resolve(callback).then((view) => {
        this.viewInstance = view()
        this.viewInstance.render()
      })
    }
  }

  getCurrentRoute () {
    let route = null
    const fragment = Backbone.history.getFragment()

    any(Backbone.history.handlers, function (handler) {
      if (handler.route.test(fragment)) {
        route = handler
        return true
      }
    })
    return route
  }

  modalRoute (modal, fragment) {
    this.modal = modal
    const currentUrl = Backbone.history.fragment

    Backbone.history.navigate(fragment, { replace: true })
    modal.show()
    return modal.on('hidden.bs.modal', () => {
      this.modal = null
      return Backbone.history.navigate(currentUrl, { replace: true })
    })
  }

  async mount (module, ...args) {
    const { default: view } = module
    if (view) this.getView(() => view(...args))
  }

  async mountInstance (module, ...args) {
    const { default: View } = module
    if (View) this.getView(() => new View(...args))
  }

  notfound () {
    this.viewName = this.viewInstance = null
    if (this.modal != null) {
      this.modal.hide()
      return (this.modal = null)
    }
  }

  //
  // routes
  //
  async aboutCollegiate () {
    return this.mountInstance(await import('views/about/collegiate'), {
      el: 'body',
    })
  }

  async aboutTestimonials () {
    return this.mountInstance(await import('views/about/testimonials'), {
      el: '#testimonials',
    })
  }

  async aboutWebwork () {
    return this.mountInstance(await import('views/about/collegiate'))
  }

  async accessCodesIndex () {
    return this.mount(await import('mounts/access_codes_index'))
  }

  async activationsIndex () {
    return this.mount(await import('mounts/activations_index'))
  }

  async supportIndex (route, id) {
    this.mount(await import('mounts/support_index'), route, id)
  }

  async assessmentNew () {
    this.mount(await import('mounts/assessment_new'))
  }

  async assessmentLdbLaunch () {
    this.mount(await import('mounts/assessment_ldb_launch'))
  }

  async assessmentSessionNew () {
    this.mount(await import('mounts/assessment_session_new'))
  }

  async assessmentSessionShow (_id, route) {
    if (!_E.suspended) {
      if (_E.assessment) {
        this.mount(await import('mounts/assessment_show'), route)
      } else {
        this.mount(await import('mounts/assessment_session_show'), route)
      }
    }
  }

  async assessmentItemShow (_id, route) {
    this.mount(await import('mounts/assessment_item'), route)
  }

  async assignmentShow (_id, route) {
    this.mount(await import('mounts/assignment_show'), route)
  }

  async cartCheckout () {
    this.mount(await import('mounts/cart_checkout'))
  }

  async cartEdit () {
    this.mount(await import('mounts/cart_edit'))
  }

  async contestSponsorIndex () {
    return this.mount(await import('mounts/contest_sponsors_index'))
  }

  async environmentTemplateShow (_id, route) {
    return this.mount(await import('mounts/environment_template_show'), route)
  }

  async environmentTemplatesIndex () {
    return this.mount(await import('mounts/environment_templates_index'))
  }

  async homeIndex () {
    if (_E.currentUser.guest) {
      this.mountInstance(await import('views/home/index'), {
        el: 'body',
      })
    } else if (_E.currentUser.educator || _E.currentUser.ta) {
      this.mount(await import('mounts/home_educator'))
    } else {
      this.mount(await import('mounts/registrations_index'))
    }
  }

  async sbIndex (route) {
    this.mount(await import('mounts/sb_index'), route)
  }

  async ltiSelectAssignment () {
    this.mount(await import('mounts/lti_select_assignment'))
  }

  async ltiaCourseLink () {
    this.mount(await import('mounts/ltia_course_link'))
  }

  async ltiaDeepLink (_id) {
    this.mount(await import('mounts/ltia_deep_link'))
  }

  async ltiaRegistrationEdit () {
    this.mount(await import('mounts/ltia_registration_edit'))
  }

  async printVariationShow (_aid, id, route) {
    this.mount(await import('mounts/print_variation_show'), id, route)
  }

  async problemShow (_id, route) {
    this.mount(await import('mounts/problem_show'), route)
  }

  async problemShowInProblemSet (_sid, id, route) {
    return this.problemShow(id, route)
  }

  async productCategoryIndex () {
    return this.mount(await import('mounts/product_categories_index'))
  }

  async productCategoryEdit () {
    return this.mount(await import('mounts/product_category_edit'))
  }

  async productsIndex () {
    return this.mount(await import('mounts/products_index'))
  }

  async productEdit (_id, route) {
    return this.mount(await import('mounts/product_edit'), route)
  }

  async productShow () {
    return this.mount(await import('mounts/product_show'))
  }

  async registrationsIndex () {
    return this.mount(await import('mounts/registrations_index'))
  }

  async registrationShow (_id, route) {
    return this.mount(await import('mounts/registration_show'), route)
  }

  async schoolShow (id, route, uid) {
    this.mount(await import('mounts/school_show'), id, route, uid)
  }

  async sketchesIndex () {
    return this.mount(await import('mounts/sketches_index'))
  }

  async sketchRequestShow (_id, route) {
    this.mount(await import('mounts/sketch_request_show'), route)
  }

  async sketchRequestsIndex () {
    return this.mount(await import('mounts/sketch_requests_index'))
  }

  async sketchShow (_id, route) {
    return this.mount(await import('mounts/sketch_show'), route)
  }

  async sectionJoin () {
    return this.mount(await import('mounts/section_join'))
  }

  async sectionRenew (_id) {
    return this.mountInstance(await import('views/sections/join'))
  }

  async sectionShow (id, route) {
    if (route !== 'renew') {
      this.mount(await import('mounts/section_show'), route)
    }
  }

  async sectionsIndex (route) {
    this.mount(await import('mounts/sections_index'), route)
  }

  async ordersAll () {
    this.mount(await import('mounts/orders_all'))
  }

  async ordersEdit () {
    this.mount(await import('mounts/orders_edit'))
  }

  async ordersShow () {
    this.mount(await import('mounts/orders_show'))
  }

  async sessionNew () {
    this.mountInstance(await import('views/sessions/new'), {
      el: 'body',
    })
  }

  async taxonomyNodeShow (_id, route) {
    this.mount(await import('mounts/taxonomy_node_show'), route)
  }

  async userEditEducator () {
    this.mount(await import('mounts/user_edit_educator'))
  }

  async userEditStudent () {
    this.mount(await import('mounts/user_edit_student'))
  }

  async userLogin2FA () {
    this.mountInstance(await import('views/sessions/login2FA'), {
      el: 'body',
    })
  }

  async usersIndex (route) {
    if (_E.post || _E.currentUser.guest) {
      this.mount(await import('mounts/users_sign_up'))
    } else if (
      route == null ||
      [
        'billing',
        'reports',
        'settings',
        'communication',
        'accessibility',
      ].includes(route)
    ) {
      this.mount(await import('mounts/users_index'), route)
    } else {
      this.mountInstance(await import('views/users/show'), {
        products: _E.products,
        el: 'body',
      })
    }
  }
}

// Backbone requires routes to be set on the prototype of the router object
export default () => {
  Router.setRoutes()
  return new Router()
}
